<!--电能设置--基本设置---->
<template>
  
  <div id="intensityIndex" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <div class="headertitle">
      
      <!-- 基本单位指标值 -->
      <span>{{$t('energy.enedata252')}}</span>
    </div>
    <div class="header">
      <div class="header-left">
        <div class="header-condition">
          <span>{{$t('energy.enedata046')}}：</span>
          <a-select v-model="selYear" style="width: 150px">
            <a-select-option
              v-for="(itme, index) in yearList"
              :key="index"
              :value="itme"
            >
              {{ itme }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div>
        <a-button type="primary" style="width: 120px"  @click="loadIntensityIndex(1)"> {{$t('energy.enedata006')}} </a-button>
      </div>
    </div>
    <div class="main">
         <a-table :columns="columns" :data-source="data" bordered size="small" :pagination="false" :scroll="{ x: 1000 }">
            <template 
             v-for="col1 in ['apr',	'aug',	'dec',	'feb',	'jan',	'jul',	'jun',	'mar',	'may',	'nov',	'oct',	'sep']"
            :slot="col1"
            slot-scope="text, record, index,key "
            >
            <div :key="col1">
                <a-input-number style="width:100%;"  :value="text" :disabled="record.yearCommonFlag"   @change="changeValue2($event,record,key)"/>
            </div>
            </template>
            
           <template
            v-for="col in ['yearValue']"
            :slot="col"
            slot-scope="text, record, index"
            >
            <div :key="col">
                <a-input-number  style="width:100%;"  v-model="record.yearValue" :disabled="!record.yearCommonFlag" @change="changeValue(index)"  />
               
            </div>
            </template>

            <template
            v-for="col2 in ['yearCommonFlag']"
            :slot="col2"
            slot-scope="text, record, index"
            >
            <div :key="col2">
               <a-checkbox :checked="record.yearCommonFlag" @change="yearCommonFlagChange(index,record.yearCommonFlag)"></a-checkbox>
            </div>
            </template>
        </a-table>
    </div>
    
    <div class="button">
       <a-button type="primary" @click="search()">{{$t('energy.enedata128')}}</a-button>
       <a-button type="primary" @click="saveintensityIndex()">{{$t('energy.enedata173')}}</a-button>
    </div>

  </div>
</template>



<script>
import { getIntensityIndexData  , saveIntensityIndexData } from "../../../api/energy";

const columns = [];

export default {
  name: "intensityIndex",
  data() {
      
    return {
      loading: false,
      dataChange:false,
      selYear:0,
      yearList:[],
      intensities:null,
      data:null,
      errorMessage:[
           this.$t('energy.enedata252')//"原单位指标值"                              // 0
         , this.$t('energy.enedata180')//"数据注册成功。"                            // 1
         , this.$t('energy.enedata181')//"数据注册失败。"                            // 2
         , this.$t('energy.enedata253')//"确认要保存所设置的原单位指标值吗？"           // 3 
         , this.$t('energy.enedata186')//"确认要放弃所编辑的数据吗？"        
        ] ,
      columns:[
            {title: this.$t('energy.enedata254')		  , dataIndex: 'siteName'		    , key: 'siteName'		     ,width:150, fixed: 'left'},
            {title: this.$t('energy.enedata163')	    , dataIndex: 'basicUnitName'  , key: 'basicUnitName'   ,width:150, fixed: 'left'},
            {title: this.$t('energy.enedata165')			, dataIndex: 'unit'			      , key: 'unit'			       ,width:80, fixed: 'left'},
            {title: this.$t('energy.enedata255')      , dataIndex: 'yearCommonFlag' , key: 'yearCommonFlag',  scopedSlots: { customRender: 'yearCommonFlag' },width:90, },
            {title: this.$t('energy.enedata168')      , dataIndex: 'yearValue'      , key: 'yearValue'     ,  scopedSlots: { customRender: 'yearValue' } ,},
            {title: this.$t("energy.enedata310"),dataIndex: 'apr', key: 'apr',  scopedSlots: { customRender: 'apr' },width:95,},
            {title: this.$t("energy.enedata311"),dataIndex: 'may', key: 'may',  scopedSlots: { customRender: 'may' },width:95,},
            {title: this.$t("energy.enedata312"),dataIndex: 'jun', key: 'jun',  scopedSlots: { customRender: 'jun' },width:95,},
            {title: this.$t("energy.enedata313"),dataIndex: 'jul', key: 'jul',  scopedSlots: { customRender: 'jul' },width:95,},
            {title: this.$t("energy.enedata314"),dataIndex: 'aug', key: 'aug',  scopedSlots: { customRender: 'aug' },width:95,},
            {title: this.$t("energy.enedata315"),dataIndex: 'sep', key: 'sep',  scopedSlots: { customRender: 'sep' },width:95,},
            {title: this.$t("energy.enedata316"),dataIndex: 'oct', key: 'oct',  scopedSlots: { customRender: 'oct' },width:95,},
            {title: this.$t("energy.enedata317"),dataIndex: 'nov', key: 'nov',  scopedSlots: { customRender: 'nov' },width:95,},
            {title: this.$t("energy.enedata318"),dataIndex: 'dec', key: 'dec',  scopedSlots: { customRender: 'dec' },width:95,},
            {title: this.$t("energy.enedata319"),dataIndex: 'jan', key: 'jan',  scopedSlots: { customRender: 'jan' },width:95,},
            {title: this.$t("energy.enedata320"),dataIndex: 'feb', key: 'feb',  scopedSlots: { customRender: 'feb' },width:95,},
            {title: this.$t("energy.enedata321"),dataIndex: 'mar', key: 'mar',  scopedSlots: { customRender: 'mar' },width:95,},
        ]
    };
  },
  computed: {
    
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.loadIntensityIndex(1);
    }
  },
  async mounted() { 
    //this.loadIntensityIndex(1);
  },
  methods:{
    search(){
       if(this.dataChange){
        this.$confirm({
        title: this.errorMessage[4],
        centered: true,
        onOk: () => {
              this.loadIntensityIndex(1);
             }
        });
      }else{
          this.loadIntensityIndex(1);
      }
    },
    loadIntensityIndex(action){
      let params = {
            action:action,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            selYear:this.selYear,
            tenantId: 0,
        };
        console.log(params);
        this.loading = true;
    
        getIntensityIndexData(params)
        .then((res) => {
            console.log(res);
            this.loading = false;
            let normList = [];
            if(res.errorCode == '00'){
                this.selYear = res.data.selYear;
                this.yearList = res.data.yearList;
                if(res.data.basicUnitNormValueDatas!=null){
                    for (let i = 0; i < res.data.basicUnitNormValueDatas.length; i++) {
                        let unv = res.data.basicUnitNormValueDatas[i];
                        let vail = this.getDefaultVail();
                        let intensity = {
                            data:unv,
                            vail:vail
                        };
                        normList.push(intensity);
                    }
                    this.data = res.data.basicUnitNormValueDatas;
                }
           
            }
            console.log(normList);
            this.intensities = normList;
        })
      .catch((err) => {
            this.loading = false;
            console.log(err);
      });
    },
    yearCommonFlagChange(index,bol){
      console.log("yearCommonFlagChange>>"+index);
      this.data[index].yearCommonFlag = !bol;
      this.data[index].inputFlag = true;

    },
    changeValue(index){
      console.log("changeValue>>"+index);
      this.data[index].inputFlag = true;
      this.dataChange = true;
    },
    changeValue2(val,record,obj){
        if(obj.key=='apr'){record.apr=val;this.dataChange = true;}
        else if(obj.key=='aug'){record.aug=val;this.dataChange = true;}
        else if(obj.key=='dec'){record.dec=val;this.dataChange = true;}
        else if(obj.key=='feb'){record.feb=val;this.dataChange = true;}
        else if(obj.key=='jan'){record.jan=val;this.dataChange = true;}
        else if(obj.key=='jul'){record.jul=val;this.dataChange = true;}
        else if(obj.key=='jun'){record.jun=val;this.dataChange = true;}
        else if(obj.key=='mar'){record.mar=val;this.dataChange = true;}
        else if(obj.key=='may'){record.may=val;this.dataChange = true;}
        else if(obj.key=='nov'){record.nov=val;this.dataChange = true;}
        else if(obj.key=='oct'){record.oct=val;this.dataChange = true;}
        else if(obj.key=='sep'){record.sep=val;this.dataChange = true;}       
    },
    saveintensityIndex(){
      let err = 0;
      let paramArr = [];
    //   for (let k = 0; k < this.intensities.length; k++) {
    //     this.setDefaultVail(k);
    //     this.intensities[k].data.year = this.selYear;
    //     paramArr.push(this.intensities[k].data);
    //   }

       for (let k = 0; k < this.data.length; k++) {
        this.data[k].year = this.selYear;
        paramArr.push(this.data[k]);
      }
     
      if(err>0) {
        let msg = this.errorMessage[0] ;//+this.errorMessage[err];
        this.$message.error(msg);
        return;
      }
      this.$confirm({
        title: this.errorMessage[3],
        centered: true,
        onOk: () => {
              this.loading = true;
              let params = {
                action:2,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                selYear: this.selYear,
                basicUnitNormValueDatas:paramArr
              }
              console.log(params);
              saveIntensityIndexData(params)
              .then((res) => {
                let msg = '';
                if(res.errorCode=="00"){
                  msg = this.errorMessage[0]+this.errorMessage[1];
                  this.loadIntensityIndex(1);
                  this.$message.success(msg);
                }else{
                    msg = this.errorMessage[0]+this.errorMessage[2];
                  this.$message.error(msg);
                }
              })
              .catch((err) => {
                this.loading = false;
                console.log(err);
              });
             }
        });
    },
    getDefaultVail(calc){
       let vail = {
            apr :"",
            aug :"",
            dec :"",
            feb :"",
            jan :"",
            jul :"",
            jun :"",
            mar :"",
            may :"",
            nov :"",
            oct :"",
            sep :"",
            year : ""
        }
        return vail;
    },
    setDefaultVail(index){
        this.intensities[index].vail.apr = "";
        this.intensities[index].vail.aug = "";
        this.intensities[index].vail.dec = "";
        this.intensities[index].vail.feb = "";
        this.intensities[index].vail.jan = "";
        this.intensities[index].vail.jul = "";
        this.intensities[index].vail.jun = "";
        this.intensities[index].vail.mar = "";
        this.intensities[index].vail.may = "";
        this.intensities[index].vail.nov = "";
        this.intensities[index].vail.oct = "";
        this.intensities[index].vail.sep = "";
        this.intensities[index].vail.year = "";
    }
  },
};
</script>

<style scoped>

#intensityIndex {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'

}
.headertitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.headertitle::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  margin-right: 20px;
  background-color: #7682ce;
}
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 3px 8px #e7e6e6;
  overflow: hidden;
}
.header-left {
  display: flex;
}
.header-condition {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 25px;
}
.main{
    width: 100%;
    box-shadow: 0px 3px 8px #e7e6e6;
}

.button{
    display: flex;
    justify-content: center;
    margin-top: 30px    ;
}
input {
  width: 14%;
}
button {
  width: 120px;
  margin: 0 5px;
}
.error{
  border: 1px solid #FF0000;
}
</style>


// handleChange(value, key, column) {
//       const newData = [...this.data];
//       const target = newData.filter(item => key === item.key)[0];
//       if (target) {
//         target[column] = value;
//         this.data = newData;
//       }
//     }